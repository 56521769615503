import { Context } from '@nuxt/types';
import redirectConfig from '../redirects.json';
/**
 * Redirect middleware is not applied by default. In order to make it work, you need to add it to the `middleware` property in the requested page.
 */
export default function ({ redirect, route }: Context) {
  const redirects = redirectConfig;
  const toPath = route.fullPath.split('?')[0];
  const pathConfig = redirects.find((r) => {
    // We allow only wildcard redirects at the end of url
    if (r.from.endsWith('*')) {
      return toPath.startsWith(r.from.replace('*', ''));
    } else {
      return r.from === toPath;
    }
  });
  if (pathConfig) {
    if (pathConfig.from.endsWith('*')) {
      const toUrl = pathConfig.to.replace('$$', toPath.replace(pathConfig.from.replace('*', ''), ''));
      redirect(toUrl, route.query);
    } else {
      redirect(pathConfig.to, route.query);
    }
  }
}
