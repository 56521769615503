
import Vue from 'vue';
import { Prop } from 'vue/types/options.d';
import { SimpleLinkNavigationItemType as ISimpleLinkNavigationItemType } from '@localazy/directus-service/lib/@types';
import isAbsoluteUrl from '@/modules/navigation/utils/is-absolute-url';
import { LocIcon } from '@localazy/components';
import NavigationNuxtLink from '@/modules/navigation/components/NavigationNuxtLink.vue';

export type SimpleLinkType = Pick<ISimpleLinkNavigationItemType, 'url' | 'url_text' | 'show_favorite_icon'>;

export default Vue.extend({
  name: 'SimpleLinkNavigationItemType',
  components: {
    LocIcon,
    NavigationNuxtLink
  },
  props: {
    item: {
      type: Object as Prop<SimpleLinkType>,
      required: true
    }
  },
  computed: {
    isAbsoluteLink(): boolean {
      return isAbsoluteUrl(this.item.url);
    }
  }
});
