
import Vue from 'vue';
import { Integration } from '@localazy/directus-service/lib/@types';
import { Prop } from 'vue/types/options.d';

export default Vue.extend({
  props: {
    integrations: {
      type: Array as Prop<Integration[]>,
      required: true
    }
  }

});
