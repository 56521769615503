/* eslint-disable @typescript-eslint/no-unused-vars */
import { joinURL, withoutTrailingSlash } from 'ufo';
import { fileUrl } from '@localazy/directus-service';
import { ResolvedImage, ImageModifiers, ImageCTX, CreateImageOptions } from '@nuxt/image';
import { merge } from 'lodash-es';
import toQueryString from '@/modules/@common/functions/to-query-string';

type Modifiers = { modifiers: ImageModifiers, baseURL: string };
type Context = ImageCTX & { options: CreateImageOptions & { transform: string } }

/*
* Resolves directus url with common image modifiers translated to directus syntax
* @see https://docs.directus.io/reference/files.html#custom-transformations
* @param {string} src can be either Directus ID or full url.
*/
export function getImage(src: string, { modifiers, baseURL }: Partial<Modifiers> = {}, { options, nuxtContext, $img }: Context): ResolvedImage {
  const defaultModifiers: Partial<ImageModifiers> = {
    format: 'webp',
    quality: '80'
  };
  const directusParams = () => ({
    width: modifiers?.width,
    height: modifiers?.height,
    quality: modifiers?.quality,
    format: modifiers?.format,
    fit: modifiers?.fit
  });
  const query = '?' + toQueryString(merge(directusParams(), defaultModifiers));

  return {
    url: src.startsWith('https://') ? joinURL(src, query) : withoutTrailingSlash(joinURL(fileUrl(src), query))
  };
}
