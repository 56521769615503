
import Vue, { PropType } from 'vue';
import { Homepage } from '@localazy/directus-service/lib/@types';

export default Vue.extend({
  name: 'FooterBadges',
  props: {
    homepage: {
      type: Object as PropType<Homepage>,
      required: true
    }
  },
  mounted () {
    this.loadSourceForgeIfNeeded();
  },
  computed: {
    hasSourceForgeBadge():boolean {
      return this.homepage
        .footer_badges.some(badge => badge.badgeType === 'Source Forge');
    }
  },
  methods: {
    loadSourceForgeIfNeeded() {
      if (this.homepage.footer_badges && this.hasSourceForgeBadge) {
        this.loadSourceForgeBadges();
      }
    },
    loadSourceForgeBadges () {
      const isSourceForgeLoaded = Array.from(document.scripts)
        .some(script => script.src.includes('b.sf-syn.com/badge_js'));
      if (isSourceForgeLoaded) {
        return;
      }

      const sc = document.createElement('script');
      sc.type = 'text/javascript';
      sc.defer = true;
      sc.src = 'https://b.sf-syn.com/badge_js?sf_id=3308406&variant_id=sf';
      const p = document.getElementsByTagName('script')[0];
      if (p && p.parentNode) {
        p.parentNode.insertBefore(sc, p);
      }
    }
  }
});
