
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
import findCoordinatesById from '@/functions/find-coordinates-by-id';
import LocAlertsContainer from '@/modules/alerts/components/LocAlertsContainer.vue';

export default {
  components: {
    AppHeader,
    AppFooter,
    LocAlertsContainer
  },
  middleware: 'track-pageview',
  mounted () {
    // Be design, vue-router's scroll to anchor does not work on initial page load
    // Thus we handle the initial scroll manually
    // See https://github.com/nuxt/nuxt.js/issues/4024 for brief reference of this
    this.checkPageScroll();
    this.checkForSuccess();
  },
  // head () {
  // NOTE: This adds "canonical" referencing self url to each page
  // That's problematic for Google when there is no true alternative
  // return this.$nuxtI18nHead({ addSeoAttributes: true });
  // }
  methods: {
    checkPageScroll() {
      if (this.$route.hash) {
        this.$nextTick(() => {
          const coordinates = findCoordinatesById(this.$route);
          window.scrollTo(coordinates.x, coordinates.y);
        });
      }
    },
    checkForSuccess() {
      // If the query contains audience parameter we show a success notification. The subscritpion mechanism is handled on BE
      // The url looks like https://localazy.com/?audience=warm_interest_campaign_languagemonth:${userHexId}
      if (this.$route.query?.audience) {
        this.$store.commit('PUSH_ALERT', {
          id: 'sa098d80as98d',
          title: 'Success! You\'re now subscribed to our emails.',
          type: 'success',
          delay: 8000
        });
      }
    }
  }
};
