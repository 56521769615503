
import Vue from 'vue';
import { PropOptions } from 'vue/types/options';
import { RawLocation } from 'vue-router';
import NavigationStateService from '@/modules/navigation/services/navigation-state-service';

export default Vue.extend({
  name: 'NavigationNuxtLink',
  props: {
    to: {
      required: true,
      validator: (prop: String | Object) => typeof prop === 'string' || typeof prop === 'object'
    } as PropOptions<string | RawLocation>
  },
  methods: {
    onNavigation() {
      const isNavigatingToSamePage = this.$route.path.includes(typeof this.to === 'string' ? this.to : this.to.path || '');
      if (isNavigatingToSamePage) {
        NavigationStateService.getInstance().closeMobileMenu();
        NavigationStateService.getInstance().collapseAll();
      }
    }
  }
});
