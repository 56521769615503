
import ApiFactory from '@localazy/core/dist/modules/api/services/api-factory';
import { resolveAuthenticatedApiConfig } from '@/api/functions/resolve-authenticated-api-config';

export const baseURL = process.env.NODE_ENV === 'development' && process.client ? '/api' : `${process.env.API_URL}/api`;
export const getAxiosConfigOnRuntime = () => resolveAuthenticatedApiConfig(baseURL);

export const createAuthenticatedApi = () => {
  const config = resolveAuthenticatedApiConfig(baseURL);

  return ApiFactory(baseURL, config);
};
