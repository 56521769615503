
import Vue from 'vue';
import FooterSocial from '@/components/footer/FooterSocial.vue';
import AnalyticsService from '@/plugins/analytics-service';

export default Vue.extend({
  components: {
    FooterSocial
  },
  methods: {
    openConsentManager() {
      AnalyticsService.openConsentManager();
    }
  }
});
