
import Vue from 'vue';
import { LocIcon } from '@localazy/components';
import LogoBlue from '@/assets/svg/logos/LogoBlue.vue';
import LoginToolAutomated from '@/modules/login/components/LoginToolAutomated.vue';
import SignUpLocalazyPerk from '@/modules/login/components/SignUpLocalazyPerk.vue';
import SocialProofContainer from '@/modules/login/components/SocialProofContainer.vue';
import { languageService } from '@localazy/directus-service';

interface IData {
  linkHovered: boolean;
  isMaintenanceMode: boolean;
}

export default Vue.extend({
  name: 'LoginSignupUserFocusLayout',
  middleware: 'track-pageview',
  components: {
    LocIcon,
    LogoBlue,
    LoginToolAutomated,
    SignUpLocalazyPerk,
    SocialProofContainer
  },
  data(): IData {
    return {
      linkHovered: false,
      isMaintenanceMode: false
    };
  },
  computed: {
    rightSideContent(): any {
      if (this.isMaintenanceMode) {
        return () => import('@/modules/login/components/MaintenanceScreen.vue');
      }

      const page = this.$route.name;
      switch (page) {
        case 'login':
          return LoginToolAutomated;
        case 'register':
        case 'lost-password':
        case 'reset-password':
        default:
          return SignUpLocalazyPerk;
      }
    }
  },
  created() {
    languageService.changeLocale('xxa');
    languageService.setFallbackLanguages(['xxa']);
  },
  mounted() {
    this.$nuxt.$on('on-services-status-fetched', (isMaintenanceMode: boolean) => {
      this.isMaintenanceMode = isMaintenanceMode;
    });
  },
  head() {
    return {
      script: [
        {
          vmid: 'vmid:gsi-loaded',
          src: 'https://accounts.google.com/gsi/client',
          callback: () => {
            window.dispatchEvent(new Event('gsi-loaded'));
          }
        }
      ]
    };
  }
});
