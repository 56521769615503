
import Vue from 'vue';
import { LocIcon } from '@localazy/components';
import Love from '@/assets/svg/scribbles/Love.vue';
import LineSimple from '@/assets/svg/scribbles/LineSimple.vue';
import SignUpBenefit from '@/modules/login/components/SignUpBenefit.vue';

export default Vue.extend({
  name: 'SignUpLocalazyPerk',
  components: {
    LocIcon,
    Love,
    LineSimple,
    SignUpBenefit
  }
});
