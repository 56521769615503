import { Route } from 'vue-router';

/**
 * Find element on page and return it's coordinates
 */
export default (to: Route) => {
  const scrollTo = { x: 0, y: 0 };
  if (to.hash) {
    const strippedHash = to.hash.replace('#', '');
    const element = document.getElementById(strippedHash);
    if (element) {
      scrollTo.y = element.getBoundingClientRect().top + window.pageYOffset;
      scrollTo.x = element.getBoundingClientRect().left + window.pageXOffset;
    }
  }
  return scrollTo;
};
