import auth from '@/api/auth';
import AuthToken from '@/modules/@common/models/auth-token';
import { AxiosRequestConfig } from 'axios';
import { merge } from 'lodash-es';

export default async (config?: AxiosRequestConfig): Promise<AuthToken> => {
  try {
    const localConfig = {};
    merge(localConfig, config, {
      headers: {
        'Content-type': 'text/plain; charset=UTF-8',
        Accept: 'text/plain; charset=UTF-8'
      }
    });

    const result = await auth.get('/token', localConfig);
    return result.data;
  } catch (e: any) {
    throw e.data;
  }
};
