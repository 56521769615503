
import Vue from 'vue';
import axios from 'axios';

type IData = {
  serviceStatus: 'Operational' | 'Downgraded' | 'Down';
}

export default Vue.extend({
  name: 'ServiceStatus',
  data():IData {
    return { serviceStatus: 'Operational' };
  },
  async mounted() {
    await this.fetchServiceStates();
  },
  methods: {
    async fetchServiceStates() {
      try {
        const serverStates = await Promise.all([
          axios.get(this.buildMonitoringUrl('monitor-services'))
        ]);
        const resolvedServerStates = serverStates
          .filter(response => response.status === 200)
          .map(response => response.data);
        this.serviceStatus = this.isLocalazyApiWorking(resolvedServerStates) ? 'Operational' : 'Downgraded';
      } catch (e: any) {
        this.serviceStatus = 'Down';
      }
    },
    buildMonitoringUrl(target: string) {
      return process.env.NODE_ENV === 'development'
        ? `/monitoring/${target}.txt`
        : `https://monitoring-s1.localazy.com/${target}.txt`;
    },
    isLocalazyApiWorking(serverStates: string[]) {
      return serverStates.some((state) => {
        return state.includes('localazy-api') &&
        state.includes('localazy-payments') &&
        state.includes('localazy-processors') &&
        state.includes('localazy-generic-connector') &&
        state.includes('localazy-public-api') &&
        state.includes('localazy-web') &&
        state.includes('localazy-sso') &&
        state.includes('localazy-notifier');
      });
    }
  }
});
