import { render, staticRenderFns } from "./FooterMenu.vue?vue&type=template&id=2561c512"
import script from "./FooterMenu.vue?vue&type=script&lang=ts"
export * from "./FooterMenu.vue?vue&type=script&lang=ts"
import style0 from "./FooterMenu.vue?vue&type=style&index=0&id=2561c512&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports