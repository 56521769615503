
import Vue, { PropOptions } from 'vue';
import { Brand } from '@localazy/directus-service/lib/@types';
import FeaturedBrand from '@/modules/featured-brands/components/FeaturedBrand.vue';
import { LocCarousel } from '@localazy/components';

export default Vue.extend({
  name: 'SocialProofSlider',
  components: {
    FeaturedBrand,
    LocCarousel
  },
  props: {
    brands: {
      type: Array,
      required: true
    } as PropOptions<Brand[]>
  },
  data () {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
        centeredSlides: true,
        slidesPerView: 3,
        pagination: {
          enabled: false
        }
      }
    };
  },
  computed: {
    brandsWithOptimizedImages (): Array<Brand & {optimizedImage: string}> {
      return this.brands.map((brand) => {
        return {
          ...brand,
          optimizedImage: this.$img(brand.image.id, { quality: 90 }, { provider: 'directus' })

        };
      });
    }
  }
});
