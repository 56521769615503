import Cookies from 'browser-cookies';
/**
 * Detect if user is logged in based on auth cookie.
 * This is much faster than fetching it via API.
 * Client-only
 */
export default (): boolean => {
  const AUTH_COOKIE_NAME = '.lAuth';
  return !!Cookies.get(AUTH_COOKIE_NAME);
};
