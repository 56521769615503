
import Vue from 'vue';
import { Prop } from 'vue/types/options.d';
import { LocIcon } from '@localazy/components';
import NavigationItemTypesContainer from '@/modules/navigation/components/NavigationItemTypesContainer.vue';
import { NavigationItemsGroup as INavigationItemsGroup } from '@localazy/directus-service/lib/@types';
import ShowAllLink from '@/modules/navigation/components/ShowAllLink.vue';

export default Vue.extend({
  name: 'NavigationItemsGroup',
  components: {
    LocIcon,
    NavigationItemTypesContainer,
    ShowAllLink
  },
  props: {
    group: {
      type: Object as Prop<INavigationItemsGroup>,
      required: true
    }
  },
  computed: {
    renderTopShowAllLink(): boolean {
      return !!this.group.render_show_all_url && this.group.show_all_url_position === 'top';
    },
    renderBottomShowAllLink(): boolean {
      return !!this.group.render_show_all_url && this.group.show_all_url_position === 'bottom';
    }
  }
});
