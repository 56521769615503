import { VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

export default {
  bind (el: HTMLElement, binding: DirectiveBinding, vNode: VNode) {
    // Provided expression must evaluate to a function.
    if (typeof binding.value !== 'function') {
      const compName = (vNode.context as any).name;
      let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
      if (compName) { warn += `Found in component '${compName}'`; }

      // eslint-disable-next-line no-console
      console.warn(warn);
    }

    // Define Handler and cache it on the element
    const { bubble } = binding.modifiers;
    const handler = (e: Event) => {
      if (bubble || (!el.contains((e.target as HTMLElement)) && el !== e.target)) {
        binding.value(e);
      }
    };
    // @ts-ignore
    el.__vueClickOutside__ = handler;

    // add Event Listeners
    document.addEventListener('click', handler);
  },

  unbind (el: HTMLElement) {
    // Remove Event Listeners
    // @ts-ignore
    document.removeEventListener('click', el.__vueClickOutside__);
    // @ts-ignore
    el.__vueClickOutside__ = null;
  }
};
