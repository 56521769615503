
import Vue from 'vue';
import { LocIcon, LocButton } from '@localazy/components';
import { PageHighlight } from '@localazy/directus-service/lib/@types';
import { PagesHighlightsService } from '@localazy/directus-service';

interface IData {
  pageHighlight: PageHighlight;
}

export default Vue.extend({
  name: 'LoginToolAutomated',
  components: {
    LocIcon,
    LocButton
  },
  async created() {
    try {
      this.pageHighlight = await PagesHighlightsService.fetchPageHighlight(this.$route.name as string);
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e.message || e);
    }
  },
  data(): IData {
    return {
      pageHighlight: {
        created_on: '',
        id: -1,
        status: 'draft',
        page_name: '',
        highlight_description: '',
        highlight_svg: null,
        highlight_link: ''
      }
    };
  }
});
