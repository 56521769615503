import Cookies from 'browser-cookies';

const TOKEN_COOKIE_NAME = 'JS-AUTH-TOKEN';

export default class AuthService {
  /**
   * We have three types of authentication in place.
   * Production: Secure token is attached directly to window by server
   * Cookie: If undefined, token can be read from cookie named `JS-AUTH-TOKEN` (useful from testing)
   * Development: In development mode, token is injected at build time from .env and .env.local.
   */
  public static getApiToken(): string {
    if (typeof process === 'undefined' || process.client) {
      // is client
      const pageToken = (window as any).PAGE_AUTH_TOKEN;
      if (pageToken !== undefined && pageToken !== '') {
        return pageToken;
      }
      const apiToken = Cookies.get(TOKEN_COOKIE_NAME);
      if (apiToken === null) {
        return process.env.VUE_APP_TEST_TOKEN as string;
      }
      return apiToken;
    }

    // is server
    return (globalThis as any).PAGE_AUTH_TOKEN || '';
  }
}
