import { AxiosRequestConfig } from 'axios';
import AuthService from '@/api/auth-service';

/**
 * ! TODO: Warning - resolve
 * This function already exists in the `@localazy/core` package.
 * We cannot use it, because of the issues with `globalThis` context.
 * The current `@localazy/core` code works in the browser, but not in the Node.js environment.
 * `globalThis` exists, but does not read the `PAGE_AUTH_TOKEN` on the server, as it's `undefined`.
 * It looks those are two different `globalThis` contexts.
 *
 * Solutions tried:
 * - checked whether it matters if `@localazy/core` is linked locally (npm link) or installed
 * - Using `globalThis` polyfill
 * - Assigning `global` to `globalThis`
 *
 * Temporary solution:
 * - Copied the function here and use it.
 * - Then it works.
 */
export function resolveAuthenticatedApiConfig(baseUrl: string): AxiosRequestConfig {
  const apiToken = AuthService.getApiToken();
  return {
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'X-Localazy-Authorization': `Bearer ${apiToken}`, // Alternative auth header (for testing)
      Accept: 'application/json',
      'Content-type': 'application/json'
    },
    baseURL: baseUrl
  };
}
