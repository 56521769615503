
import Vue from 'vue';
import { Prop } from 'vue/types/options.d';
import { LocIcon } from '@localazy/components';
import { MediaFile } from '@localazy/directus-service/lib/@types';
import NavigationNuxtLink from '@/modules/navigation/components/NavigationNuxtLink.vue';

export type RelationNavigationItemTypeTile = {
  name: string;
  slug: string;
  icon: MediaFile | null;
}

export default Vue.extend({
  name: 'RelationNavigationItemTypeTile',
  components: {
    LocIcon,
    NavigationNuxtLink
  },
  props: {
    item: {
      type: Object as Prop<RelationNavigationItemTypeTile>,
      required: true
    }
  }
});
