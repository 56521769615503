import { BaseDirectusService, filterByStatusDeepArray } from '@localazy/directus-service';
import { NavigationSection } from '@localazy/directus-service/lib/@types';

const TABLE_NAME = 'navigation_section';

export default class MainNavigationService extends BaseDirectusService {
  static async fetchMainNavigation(skipCache = false): Promise<NavigationSection[]> {
    const cacheKey = `${TABLE_NAME}`;
    const cached = this.getCache<any>(cacheKey);
    if (cached && !skipCache) {
      return cached;
    }

    try {
      const result = await this.getItems<NavigationSection>(TABLE_NAME, {
        fields: [
          'id',
          'name',
          'url',
          'url_text',
          'status',

          // 'groups.navigation_items_group_id.*',
          'groups.navigation_items_group_id.id',
          'groups.navigation_items_group_id.sort',
          'groups.navigation_items_group_id.status',
          'groups.navigation_items_group_id.name',
          'groups.navigation_items_group_id.render_show_all_url',
          'groups.navigation_items_group_id.show_all_url_position',
          'groups.navigation_items_group_id.show_favorite_icon',
          'groups.navigation_items_group_id.show_all_url',
          'groups.navigation_items_group_id.represent_as_tile',

          // 'groups.navigation_items_group_id.items.item.*',
          'groups.navigation_items_group_id.items.collection',
          'groups.navigation_items_group_id.items.item.id',
          'groups.navigation_items_group_id.items.item.status',
          'groups.navigation_items_group_id.items.item.show_author_img',
          'groups.navigation_items_group_id.items.item.name',
          'groups.navigation_items_group_id.items.item.slug',
          'groups.navigation_items_group_id.items.item.blog_post_tag',
          'groups.navigation_items_group_id.items.item.description',
          'groups.navigation_items_group_id.items.item.sort',
          'groups.navigation_items_group_id.items.item.url',
          'groups.navigation_items_group_id.items.item.url_text',
          'groups.navigation_items_group_id.items.item.text',
          'groups.navigation_items_group_id.items.item.show_favorite_icon',
          'groups.navigation_items_group_id.items.item.icon.id',
          'groups.navigation_items_group_id.items.item.icon.title',

          // 'groups.navigation_items_group_id.items.item.item.item.*',
          'groups.navigation_items_group_id.items.item.item.collection',
          'groups.navigation_items_group_id.items.item.item.item.id',
          'groups.navigation_items_group_id.items.item.item.item.status',
          'groups.navigation_items_group_id.items.item.item.item.sort',
          'groups.navigation_items_group_id.items.item.item.item.slug',
          'groups.navigation_items_group_id.items.item.item.item.name',
          'groups.navigation_items_group_id.items.item.item.item.icon.id',
          'groups.navigation_items_group_id.items.item.item.item.icon.title',
          'groups.navigation_items_group_id.items.item.item.item.featured_icon.id',
          'groups.navigation_items_group_id.items.item.item.item.featured_icon.title'

          // 'groups.navigation_items_group_id.items.item.item.item.icon.*',
          // 'groups.navigation_items_group_id.items.item.item.item.featured_icon.*'
        ]
      });

      const mainNavigation = filterByStatusDeepArray(result.data);
      if (!skipCache) {
        this.setCache(cacheKey, mainNavigation);
      }
      return mainNavigation;
    } catch (e: any) {
      throw e?.data ? e.data : e;
    }
  }
}
