
import Vue from 'vue';
import { Prop } from 'vue/types/options.d';
import IBlogPost, { IBlogUser } from '@localazy/components/dist/modules/ghost/models/blog-post';

const fallbackImageUrl = 'bc1db540-c443-47b6-aef5-96efe73d4f86';
const fallbackAvatarId = '15f771c5-9088-4ba8-ae7f-9406c3b470c9';

export default Vue.extend({
  name: 'SimplifiedBlogCard',
  props: {
    post: {
      type: Object as Prop<IBlogPost>,
      required: true
    },
    showAuthorAvatar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imageUrl (): string {
      return this.post.main_image
        ? this.post.main_image
        : `https://directus9.localazy.com/assets/${fallbackImageUrl}`;
    },
    backgroundImageUrl(): string {
      return this.$img(this.imageUrl, { width: 900, quality: 80, format: 'webp' });
    },
    author (): IBlogUser {
      if (this.post) {
        return this.post.created_by || this.post.owner;
      }
      return {
        first_name: '',
        id: 0,
        last_name: ''
      };
    },
    authorAvatarUrl (): string {
      if (this.author.avatar && this.author.avatar) {
        return this.$img(this.author.avatar, { width: 64, quality: 90 });
      }
      return this.$img(fallbackAvatarId, {}, { provider: 'directus' });
    }
  }
});
