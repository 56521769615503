import { BaseDirectusService } from '@localazy/directus-service';
import { Params } from '@tryghost/content-api';
import ITag from '@localazy/components/dist/modules/ghost/models/tag';
import IBlogPost from '@localazy/components/dist/modules/ghost/models/blog-post';
import BlogPostDetail from '@localazy/components/dist/modules/ghost/models/blog-post-detail';
import BlogAuthor from '@localazy/components/dist/modules/ghost/models/blog-author';
import BaseGhostService from '@localazy/components/dist/modules/ghost/services/base-ghost-service';
import { BlogBanner } from '@localazy/directus-service/lib/@types';

const TABLE_NAME = 'blog_post';
export default class BlogService extends BaseDirectusService {
  static async fetchBlogPosts(params: Params = {}, skipCache = false): Promise<IBlogPost[]> {
    const cacheKey = `${TABLE_NAME}-all-${JSON.stringify(params)}`;
    const cached = this.getCache<IBlogPost[]>(cacheKey);
    if (cached && !skipCache) {
      return cached;
    }

    try {
      const posts = await BaseGhostService.fetchAllPosts(params);
      if (!skipCache) {
        this.setCache(cacheKey, posts);
      }
      return posts;
    } catch (e: any) {
      throw this.resolveError(e, 'Failed to fetch blog posts');
    }
  }

  static async fetchLatestBlogPosts(params: Params = {}): Promise<IBlogPost[]> {
    const cacheKey = `${TABLE_NAME}-latest-${JSON.stringify(params)}`;
    const cached = this.getCache<IBlogPost[]>(cacheKey);
    if (cached) {
      return cached;
    }

    try {
      const posts = await BaseGhostService.fetchPosts({
        ...params,
        order: ['published_at DESC', 'updated_at DESC']
      });
      this.setCache(cacheKey, posts);
      return posts;
    } catch (e: any) {
      throw this.resolveError(e, 'Failed to fetch latest blog posts');
    }
  }

  static async fetchHighlightBlogPosts(params: Params = { limit: 4 }): Promise<IBlogPost[]> {
    const cacheKey = `${TABLE_NAME}-highlight-${JSON.stringify(params)}`;
    const cached = this.getCache<IBlogPost[]>(cacheKey);
    if (cached) {
      return cached;
    }

    try {
      const posts = await BaseGhostService.fetchPosts({
        filter: 'featured:true',
        limit: params.limit,
        ...params
      });
      this.setCache(cacheKey, posts);
      return posts;
    } catch (e: any) {
      throw this.resolveError(e, 'Failed to fetch highlight blog posts');
    }
  }

  static async fetchRelatedPosts(tags: ITag[], params: Params = { limit: 4 }): Promise<IBlogPost[]> {
    const cacheKey = `${TABLE_NAME}-tag-${tags.map(tag => tag.slug)}-${JSON.stringify(params)}`;
    const cached = this.getCache<IBlogPost[]>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const posts = await BaseGhostService.fetchPosts({
        ...params,
        filter: `tags:[${tags.map(t => t.slug).join(', ')}]`,
        limit: params.limit
      });
      this.setCache(cacheKey, posts);
      return posts;
    } catch (e: any) {
      throw this.resolveError(e, 'Failed to fetch related blog posts');
    }
  }

  static async fetchPostsForTag(tagSlug: string, params: Params = {}): Promise<IBlogPost[]> {
    const cacheKey = `${TABLE_NAME}-tag-${tagSlug}-${JSON.stringify(params)}`;
    const cached = this.getCache<IBlogPost[]>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const posts = await BaseGhostService.fetchAllPosts({
        ...params,
        filter: `tag:${tagSlug}`
      });
      this.setCache(cacheKey, posts);
      return posts;
    } catch (e: any) {
      throw this.resolveError(e, 'Failed to fetch blog posts for tag');
    }
  }

  static async fetchPostsFromAuthor(authorSlug: string, params: Params = {}): Promise<IBlogPost[]> {
    const cacheKey = `${TABLE_NAME}-post-${authorSlug}-${JSON.stringify(params)}`;
    const cached = this.getCache<IBlogPost[]>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const posts = await BaseGhostService.fetchAllPosts({
        ...params,
        filter: `author:${authorSlug}`
      });
      this.setCache(cacheKey, posts);
      return posts;
    } catch (e: any) {
      throw this.resolveError(e, 'Failed to fetch blog posts for author');
    }
  }

  static async searchForPosts(lookup: string) {
    const cacheKey = `${TABLE_NAME}-post-${lookup}`;
    const cached = this.getCache<IBlogPost[]>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const posts = await BaseGhostService.searchForPosts(lookup);
      this.setCache(cacheKey, posts);
      return posts;
    } catch (e: any) {
      throw this.resolveError(e, 'Failed to search for lookup');
    }
  }

  static async fetchPost(slug: string) {
    const cacheKey = `${TABLE_NAME}-post-${slug}`;
    const cached = this.getCache<BlogPostDetail>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const post = await BaseGhostService.fetchSinglePost(slug);
      this.setCache(cacheKey, post);
      return post;
    } catch (e: any) {
      throw this.resolveError(e, 'Failed to fetch blog post');
    }
  }

  static async fetchAuthor(slug: string): Promise<BlogAuthor> {
    const cacheKey = `${TABLE_NAME}-author-${slug}`;
    const cached = this.getCache<BlogAuthor>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const author = await BaseGhostService.fetchSingleAuthor(slug);
      this.setCache(cacheKey, author);
      return author;
    } catch (e: any) {
      throw this.resolveError(e, 'Failed to fetch blog author');
    }
  }

  static async fetchSingleTag(slug: string) {
    const cacheKey = `${TABLE_NAME}-single-tag-${slug}`;
    const cached = this.getCache<ITag>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const post = await BaseGhostService.fetchSingleTag(slug);
      this.setCache(cacheKey, post);
      return post;
    } catch (e: any) {
      throw this.resolveError(e, 'Failed to fetch blog tag');
    }
  }

  static async fetchBanner(name = 'default'): Promise<BlogBanner> {
    const cacheKey = `blog-banners-${name}`;
    const cached = this.getCache<BlogBanner>(cacheKey);
    if (cached) {
      return cached;
    }
    try {
      const banner = (await this.getItems<BlogBanner>('blog_banners', { filter: { name: { _eq: name } }, fields: '*.*' })).data[0];
      this.setCache(cacheKey, banner);
      return banner;
    } catch (e: any) {
      throw this.resolveError(e, 'Failed to fetch blog banner');
    }
  }

  private static resolveError(e: any, fallbackMessage: string) {
    if (e && e.data) {
      return e.data;
    }

    if (e) {
      return e;
    }

    return fallbackMessage;
  }
}
