
import Vue from 'vue';
import { Prop } from 'vue/types/options.d';
import { Integration } from '@localazy/directus-service/lib/@types';
import NavigationNuxtLink from '@/modules/navigation/components/NavigationNuxtLink.vue';

export default Vue.extend({
  name: 'RelationNavigationItemTypeIntegration',
  components: {
    NavigationNuxtLink
  },
  props: {
    item: {
      type: Object as Prop<Integration>,
      required: true
    }
  }
});
