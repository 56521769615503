
import Vue from 'vue';
import NavigationNuxtLink from '@/modules/navigation/components/NavigationNuxtLink.vue';

export default Vue.extend({
  name: 'ShowAllLink',
  components: {
    NavigationNuxtLink
  },
  props: {
    link: {
      type: String,
      required: true
    }
  }
});
