import AuthUser from '@/modules/@common/models/auth-user';
import { IApiErrorResult } from '@/models/api-result';
import auth from '@/api/auth';
import { AxiosRequestConfig } from 'axios';

export default async (config?: AxiosRequestConfig): Promise<AuthUser | IApiErrorResult> => {
  try {
    const result = await auth.get('/user', config);

    return result.data;
  } catch (e: any) {
    if (e.message.includes('401')) {
      return { result: false };
    }
    throw e.data;
  }
};
