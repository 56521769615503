/**
 * Store initial referrer into cookie, so it can be read after signup in SPA and sent to analytics
 */
import Cookies from 'browser-cookies';
export default function() {
  const INITIAL_REFERRER_COOKIE_NAME = '.lInRef';
  const initialReferrer = document.referrer || '$direct';
  if (!Cookies.get(initialReferrer)) {
    Cookies.set(INITIAL_REFERRER_COOKIE_NAME, initialReferrer);
  }
}
