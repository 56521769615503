import { getAxiosConfigOnRuntime } from '@/api/authenticated-api';
import { IApiErrorResult } from '@localazy/core/dist/modules/api/models/api-result';
import { UserBase } from '@localazy/core/dist/modules/user/models/user-base';
import UserBaseService, { GetUserParams } from '@localazy/core/dist/modules/user/services/user-base-service';
import AuthUser from '@/modules/@common/models/auth-user';

export default class UserService extends UserBaseService {
  static async getUser(params: GetUserParams = {
    slug: '',
    skipCache: false
  }): Promise<UserBase> {
    try {
      this.setAxiosRequestConfig(getAxiosConfigOnRuntime());
      const result = await super.getUser(params);
      return result;
    } catch (e: any) {
      throw e.data || e;
    }
  }

  static async getAuthUser(): Promise<AuthUser | IApiErrorResult> {
    try {
      this.setAxiosRequestConfig(getAxiosConfigOnRuntime());
      const result = await this.axiosInstance.get('/user/sinfo');
      return result.data;
    } catch (e: any) {
      throw e.data || e;
    }
  }
}
