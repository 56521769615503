
import Vue, { VueConstructor } from 'vue';
import { Prop } from 'vue/types/options.d';
import { NavigationSection as INavigationSection } from '@localazy/directus-service/lib/@types';
import isAbsoluteUrl from '@/modules/navigation/utils/is-absolute-url';
import NavigationNuxtLink from '@/modules/navigation/components/NavigationNuxtLink.vue';

export default Vue.extend({
  name: 'NavigationSectionSimpleLink',
  props: {
    section: {
      type: Object as Prop<INavigationSection>,
      required: true
    }
  },
  components: {
    NavigationNuxtLink
  },
  computed: {
    isAbsoluteLink(): boolean {
      return isAbsoluteUrl(this.section.url);
    },
    linkComponent(): string | VueConstructor {
      if (this.isAbsoluteLink) {
        return 'a';
      }
      return NavigationNuxtLink;
    },
    componentAttrs(): object {
      if (this.isAbsoluteLink) {
        return {
          href: this.section.url
        };
      }
      return {
        to: this.section.url
      };
    }
  }
});
