
import Vue from 'vue';
import AppAlertItem from '@/modules/alerts/components/LocAlert.vue';
import IAlert from '@/modules/alerts/models/alert';

const MAX_ALERTS = 3;

export default Vue.extend({
  name: 'LocAlertsContainer',
  components: {
    AppAlertItem
  },
  computed: {
    alerts(): IAlert[] {
      return this.$store.state.alerts.slice(0, MAX_ALERTS);
    }
  },
  methods: {
    removeAlert(id:string) {
      this.$store.commit('REMOVE_ALERT', id);
    }
  }
});
