import { Context } from '@nuxt/types';
import AnalyticsService from '@/plugins/analytics-service';
import resolvePageContent from '@/modules/@common/functions/resolve-page-content-type';

export default function ({ from, route }: Context) {
  if (process.client) {
    const path = route.fullPath === '/' ? '/index' : route.fullPath;
    AnalyticsService.page(path, {
      path,
      query: route.query || '',
      setContentType: resolvePageContent(route.meta),
      referrer: from?.fullPath,
      title: route.meta && route.meta.title ? route.meta.title : '',
      component: route.name || '',
      type: 'website'
    });
  }
  return true;
}
