
import Vue from 'vue';
import { RawLocation } from 'vue-router';
import AuthUser from '@/modules/@common/models/auth-user';
import getLoginQuery from '@/functions/auth/get-login-query';

export default Vue.extend({
  components: {
  },
  data() {
    return {
      showSignButtons: true
    };
  },
  computed: {
    registerLink (): RawLocation {
      return { path: '/register', query: getLoginQuery(this.$route) };
    },
    loginLink (): RawLocation {
      return { path: '/login', query: getLoginQuery(this.$route) };
    },
    isLoggedIn (): boolean {
      if (this.authUser) {
        return this.authUser.result;
      }
      return false;
    },
    authUser (): AuthUser | null {
      return this.$store.state.authUser;
    }

  },
  mounted() {
    this.showSignButtons = this.isLoggedIn;
  }
});
