
import Vue, { VueConstructor } from 'vue';
import { Prop } from 'vue/types/options.d';
import {
  NavigationItemsGroup,
  NavigationItemsGroupItemsJunction,
  RelationNavigationItemType as IRelationNavigationItemType
} from '@localazy/directus-service/lib/@types';
import BlogPostNavigationItemType from '@/modules/navigation/components/BlogPostNavigationItemType.vue';
import SimpleLinkNavigationItemType from '@/modules/navigation/components/SimpleLinkNavigationItemType.vue';
import TextLinkNavigationItemType from '@/modules/navigation/components/TextLinkNavigationItemType.vue';
import RelationNavigationItemType from '@/modules/navigation/components/RelationNavigationItemType.vue';
import { sortItems } from '@localazy/directus-service';

export default Vue.extend({
  name: 'NavigationItemTypesContainer',
  components: {
    TextLinkNavigationItemType,
    SimpleLinkNavigationItemType,
    BlogPostNavigationItemType,
    RelationNavigationItemType
  },
  props: {
    group: {
      type: Object as Prop<NavigationItemsGroup>,
      required: true
    }
  },
  computed: {
    sortedItems(): NavigationItemsGroupItemsJunction[] {
      if (!this.group.items) {
        return [];
      }

      return sortItems(this.group.items.map(item => ({
        ...item,
        sort: item.item.sort
      })));
    },
    /**
     * Directus Admin: Be aware!
     * Generally, mixing of different item types is not supported.
     * Currently, get the first type of the group and determine the layout classes
     * Support of the combinations of types is a subject to improve
     */
    layoutClasses(): string {
      let collection;

      if (!this.group.items) {
        collection = null;
      } else {
        collection = this.group.items[0].collection;
      }

      switch (collection) {
        case 'blog_post_navigation_item_type':
          return 'blog-post-navigation-item-type flex flex-no-wrap items-center';
        case 'relation_navigation_item_type': {
          const subcollection = (this.group.items[0].item as IRelationNavigationItemType).item[0].collection;
          const commonClasses = 'relation-navigation-item-type';
          switch (subcollection) {
            case 'docs_section':
              return `${commonClasses} relation-docs-section grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-2 lg:w-80 gap-x-2 gap-y-2`;
            case 'platforms':
              return `${commonClasses} relation-integrations`;
            case 'personas':
              return this.group.represent_as_tile ? `${commonClasses} relation-personas grid gap-x-3 gap-y-3 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-2 lg:w-80` : `${commonClasses} relation-personas grid gap-x-3 gap-y-3 min-w-40 xl:min-w-48`;
            default:
              return `${commonClasses} grid gap-x-3 gap-y-3 min-w-40 xl:min-w-48`;
          }
        }
        case 'simple_link_navigation_item_type':
          return 'simple-link-navigation-item-type flex flex-col gap-y-3 h-full min-w-40 xl:min-w-48';
        case 'text_link_navigation_item_type':
          return 'text-link-navigation-item-type flex flex-col gap-y-3 h-full min-w-72';
        default:
          return 'flex flex-col gap-y-3 h-full min-w-40 xl:min-w-48';
      }
    }
  },
  methods: {
    itemComponent(junction: NavigationItemsGroupItemsJunction): VueConstructor {
      switch (junction.collection) {
        case 'blog_post_navigation_item_type':
          return BlogPostNavigationItemType;
        case 'simple_link_navigation_item_type':
          return SimpleLinkNavigationItemType;
        case 'text_link_navigation_item_type':
          return TextLinkNavigationItemType;
        case 'relation_navigation_item_type':
          return RelationNavigationItemType;
        default:
          throw new Error(`Unknown collection: ${junction.collection}`);
      }
    }
  }
});
