import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

export default function() {
  Sentry.init({
    dsn: 'https://1d30e51b708044ed8be0f7a864aca980@o377510.ingest.sentry.io/5199843',
    integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
    environment: process.env.SENTRY_ENVIRONMENT,
    ignoreErrors: [
      'swiper',
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
      'Non-Error promise rejection captured'
    ],
    denyUrls: [
      /tawk\.to/i
    ]
  });
}
