
import Vue from 'vue';
import { RawLocation } from 'vue-router';
import NavigationNuxtLink from '@/modules/navigation/components/NavigationNuxtLink.vue';
import getLoginQuery from '@/functions/auth/get-login-query';

export default Vue.extend({
  name: 'LoginButton',
  components: {
    NavigationNuxtLink
  },
  computed: {
    loginLink (): RawLocation {
      const query = getLoginQuery(this.$route);
      return { path: '/login', query };
    }
  }
});
