
import Vue from 'vue';
import { Integration, Homepage } from '@localazy/directus-service/lib/@types';
import { LocIcon } from '@localazy/components';
import FooterBadges from '@/components/footer/FooterBadges.vue';
import FooterFirm from '@/components/footer/FooterFirm.vue';
import FooterUseCases from '@/components/footer/FooterUseCases.vue';
import FooterPrograms from '@/components/footer/FooterPrograms.vue';
import FooterIntegration from '@/components/footer/FooterIntegration.vue';
import FooterMenu from '@/components/footer/FooterMenu.vue';
import FooterConnect from '@/components/footer/FooterConnect.vue';
import FooterSecuredBadges from '@/components/footer/FooterSecuredBadges.vue';
import LogoBlue from '@/assets/svg/logos/LogoBlue.vue';
import ServiceStatus from '@/modules/@common/components/ServiceStatus.vue';
import { IntegrationService, HomepageService } from '@localazy/directus-service';

type IData = {
  integrations: Integration[],
  homepage: Homepage | null,
}

export default Vue.extend({
  components: {
    LogoBlue,
    FooterFirm,
    FooterUseCases,
    FooterPrograms,
    FooterIntegration,
    FooterMenu,
    FooterConnect,
    LocIcon,
    FooterBadges,
    FooterSecuredBadges,
    ServiceStatus
  },
  async fetch () {
    this.integrations = await IntegrationService.fetchAllIntegrations({
      filter: { in_menu: { _eq: true } }
    });
    this.homepage = await HomepageService.fetchHomepage();
  },
  data (): IData {
    return {
      integrations: [],
      homepage: null
    };
  }
});
