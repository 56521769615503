
import Error404 from '@/components/errors/Error404.vue';
import Error500 from '@/components/errors/Error500.vue';
import { languageService } from '@localazy/directus-service';

export default {
  name: 'NuxtError',
  layout: 'default',
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    languageService.changeLocale('xxa');
    languageService.setFallbackLanguages(['xxa']);
  },
  computed: {
    errorPage () {
      if (this.error.statusCode === 404) {
        return Error404;
      }
      return Error500;
    }
  }
};
