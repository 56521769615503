import HubspotContentType from '@localazy/core/dist/modules/analytics/enums/hubspot-content-type';
import { Context } from '@nuxt/types';

export default function resolvePageContentType(routeMeta?: Context['route']['meta']): HubspotContentType {
  let contentType;
  if (Array.isArray(routeMeta)) {
    contentType = (routeMeta || []).find((meta: any) => meta.setContentType)?.setContentType;
  }

  if (typeof routeMeta?.setContentType === 'string') {
    contentType = routeMeta.setContentType;
  }

  if (typeof contentType !== 'string') {
    return HubspotContentType.SITE_PAGE;
  }

  switch (contentType) {
    case HubspotContentType.KNOWLEDGE_ARTICLE:
      return HubspotContentType.KNOWLEDGE_ARTICLE;
    case HubspotContentType.BLOG_POST:
      return HubspotContentType.BLOG_POST;
    case HubspotContentType.LISTING_PAGE:
      return HubspotContentType.LISTING_PAGE;
    case HubspotContentType.LANDING_PAGE:
      return HubspotContentType.LANDING_PAGE;
    default:
      return HubspotContentType.SITE_PAGE;
  }
}
