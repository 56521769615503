
import Vue from 'vue';
import { LocIcon } from '@localazy/components';

export default Vue.extend({
  name: 'SignUpBenefit',
  components: {
    LocIcon
  }
});
