import axios from 'axios';
const API_URL = process.env.API_URL;

const request = axios.create({
  baseURL: process.env.NODE_ENV === 'development' && process.client ? '/auth' : `${API_URL}/auth`,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
});

export default request;
