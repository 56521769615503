import IntercomService from '@localazy/core/dist/modules/analytics/services/intercom-service';
import AuthUser from '@/modules/@common/models/auth-user';

export default function updateUserInIntercom(authUser: AuthUser | null) {
  if (process.client) {
    const payload: Record<string, string | number> = {
      last_request_at: Date.now() /// need some change in case user in unknown, otherwise won't fire
    };
    if (!!authUser && authUser.result) {
      // UserId required, rest remembered - https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
      payload.user_id = authUser.id;
      payload.user_hash = authUser.intercomHash;
      if (authUser.email) {
        payload.email = authUser.email;
      }
    }
    IntercomService.update(payload);
  }
}
