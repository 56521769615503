
import Vue, { PropType } from 'vue';
import NavigationSection from '@/modules/navigation/components/NavigationSection.vue';
import { NavigationSection as INavigationSection } from '@localazy/directus-service/lib/@types';
import { sortItems } from '@localazy/directus-service';
import LoginButton from '@/modules/navigation/components/LoginButton.vue';
import clickOutside from '@/directives/click-outside';
import NavigationStateService from '@/modules/navigation/services/navigation-state-service';

export default Vue.extend({
  name: 'MainNavigation',
  components: {
    NavigationSection,
    LoginButton
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    },
    mainNavigation: {
      type: Array as PropType<INavigationSection[]>,
      required: true
    }
  },
  watch: {
    '$route.path': {
      handler() {
        NavigationStateService.getInstance().closeMobileMenu();
        NavigationStateService.getInstance().collapseAll();
      }
    }
  },
  directives: {
    clickOutside
  },
  computed: {
    sortedNavigation(): INavigationSection[] {
      return sortItems(this.mainNavigation);
    },
    isMobileMenuOpen(): boolean {
      return NavigationStateService.getInstance().vueInstance.isMobileMenuOpen;
    }
  },
  mounted() {
    NavigationStateService.getInstance().onToggleMobileMenu(this.onToggleMobileMenu);
  },
  beforeDestroy() {
    this.enableMobileScroll();
    NavigationStateService.getInstance().closeMobileMenu();
  },
  methods: {
    onToggleMobileMenu(isOpen: boolean) {
      if (isOpen) {
        this.disableMobileScroll();
      } else {
        this.enableMobileScroll();
      }
    },
    disableMobileScroll() {
      if (process.client) {
        // Get the current page scroll position
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        // if any scroll is attempted on small screen,
        // set scroll position to the saved value
        window.onscroll = () => {
          const lgBreakpoint = 1264;
          if (document.body.getBoundingClientRect().width < lgBreakpoint) {
            window.scrollTo(scrollLeft, scrollTop);
          }
        };
      }
    },
    enableMobileScroll() {
      if (process.client) {
        window.onscroll = () => {
        };
      }
    },
    closeMenu() {
      NavigationStateService.getInstance().closeMobileMenu();
    }
  }
});
